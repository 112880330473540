import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect:'/home',
    component: ()=> import('../views/index.vue'),
    children:[
      {
        path: '/home',
        name: 'HomeView',
        meta:{
          title:'首页',
        },
        component: ()=> import('../views/HomeView.vue'),
      },
      {
        path: '/product',
        name: 'product',
        meta:{
          title:'产品',
        },
        component: ()=> import('../views/product.vue'),
      },
      {
        path: '/price',
        name: 'price',
        meta:{
          title:'价格',
        },
        component: ()=> import('../views/price.vue'),
      },
      {
        path: '/about',
        name: 'about',
        meta:{
          title:'关于',
        },
        component: ()=> import('../views/about.vue'),
      },
      {
        path: '/login',
        name: 'login',
        meta:{
          title:'登录',
        },
        component: ()=> import('../views/login.vue'),
      },
      {
        path: '/register',
        name: 'register',
        meta:{
          title:'注册',
        },
        component: ()=> import('../views/register.vue'),
      },
      {
        path: '/forgetPassword',
        name: 'forgetPassword',
        meta:{
          title:'忘记密码',
        },
        component: ()=> import('../views/forgetPassword.vue'),
      },
      {
        path: '/user',
        name: 'user',
        meta:{
          title:'个人中心',
        },
        component: ()=> import('../views/user.vue'),
      },
      {
        path: '/news',
        name: 'news',
        meta:{
          title:'详情',
        },
        component: ()=> import('../views/news.vue'),
      },
      {
        path: '/word',
        name: 'word',
        meta:{
          title:'文档',
        },
        component: ()=> import('../views/word.vue'),
      },
    ]
  },
  {
    path: '/serviceTerms',
    name: 'serviceTerms',
    meta:{
      title:'服务条款',
    },
    component: ()=> import('../views/serviceTerms.vue'),
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    meta:{
      title:'隐私政策',
    },
    component: ()=> import('../views/privacyPolicy.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
